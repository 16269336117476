<template>
  <div class="w-full">
    <div class="relative flex flex-col gap-2">
      <div v-for="(transaction, index) in transactions" :key="index"
        class="bg-transacbg items-center p-2.5 flex gap-4 md:gap-8 border-b border-primary shadow-xl rounded-md flex-wrap">
        <div class="transaction_table flex-1">
          <div class="inner-trans flex gap-3 my-1">
            <span class="text-contracttext font-semibold bg-contractbg text-xs py-0.5 px-2 rounded-sm"
              :class="transaction.tokenclass">{{ transaction.type }}</span>
          </div>
          <div class="address flex flex-wrap items-center gap-2 mt-1.5 mb-1">
            <img src="/assets/images/transaction.svg" alt="">
            <a :href="transaction.address" class="text-white font-semibold text-base truncate md:*: max-w-56">{{
              transaction.address }}</a>
            <span class="text-sm text-white">{{ transaction.timestamp }}</span>
          </div>
        </div>
        <div class="flex gap-2 w-72">
          <img src="/assets/images/down-arrow.svg" alt="" class="w-4 h-4">
          <div class="flex flex-col gap-3">
            <div class="vault flex gap-3 items-center">
              <span class="w-5 h-5 rounded-full object-cover bg-gradient-to-r inline-block"
                :class="transaction.cls"></span>
              <a href="javascript:void(0);" class="flex gap-2">
                <span class="flex items-center gap-2 text-white">{{ transaction.vault }} <img
                    src="/assets/images/copy.svg" alt="" class="w-4 h-4" /></span>
              </a>
            </div>
          </div>
        </div>
        <div class="whitespace-pre">
          <div class="flex gap-1 items-center text-white"><strong class="me-1 ">Fees</strong>{{ transaction.fee }} <img
              src="/assets/images/flame.svg" alt="" class="w-4 h-4"></div>
        </div>
      </div>

      <div class="block-line shadow-OutShadow h-full absolute w-4 -z-20 overflow-hidden top-0 left-5">
        <div class="behind-pipe shadow-pipeShadow bg-NewpipeBg w-full h-full">
          <div :class="[moverColor]" class="w-4 h-16 duration-300 animate-moveUpDown bg-cover bg-center bg-no-repeat">
          </div>
        </div>
      </div>
      <div class="block-line shadow-OutShadow h-full absolute w-4 -z-20 overflow-hidden top-0 left-16">
        <div class="behind-pipe shadow-pipeShadow bg-NewpipeBg w-full h-full">
          <div :class="[moverColor]" class="w-4 h-16 duration-300 animate-moveUpDown bg-cover bg-center bg-no-repeat">
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'TransComponents',
  props: {
    moverColor: {
      type: String,
      default: ''
    },
    transactions: Array
  },
  data() {
    return {
     

    }
  },
  mounted() { },
}
</script>

<style scoped>
.pipeBg {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .65), hsla(0, 0%, 100%, .65)), #c2c9d1;
}

.moving1 {
  background-image: url('/public/assets/images/trans-move.svg');
}

/* .moving2{
  background: url('/public/assets/images/mover2.svg');
}
.moving3{
  background: url('/public/assets/images/mover3.svg');
} */
</style>
