<template>
    <div>
      <header class="">
        <div class="top-nav  bg-jexagradient  flex  items-center justify-center  text-white relative z-999">
          <div class="container m-auto ">
            <div class="main-top flex justify-center items-center gap-6 p-2">
              <div class="top-text">
                <p class="text-white font-light">Jexa Now Live!</p>
              </div>
              <!-- <div class="top-btn hidden sm:block">
                <button type="" class="bg-white rounded-full px-3 py-1.5 text-sm font-semibold shadow-lg text-NewBlack">Check Out DeFi Opportunities Here</button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="bottom_header max-w-screen-xl 2xl:max-w-1450 mx-auto px-3 xs:px-5 sm:px-6 xl:px-4 2xl:px-2 py-4 flex flex-row items-center lg:border-none top-0 w-full z-50">
              <div class="container flex items-center justify-between m-auto">
                  <div class="main-logo flex-1">
                    <div class="flex items-center justify-between">
                        <router-link to="/" class="text-4xl">
                          <img src="/assets/images/logo.png" alt="" width="200">
                        </router-link>

                        <div class="xl:hidden">
                          <div class="mobile-menu hamburger-menu "  @click="toggleMenu">
                                <img src="/assets/images/menu.svg" alt="" class="w-5" >
                            </div>
                        </div>
                       
                    </div>
                  </div>
                  <nav class="hidden xl:block" >
                    <ul class="flex gap-8 items-center">
                      <li>
                        <a href="#Sectionblock" class="text-white font-normal not-italic text-base hover:text-primary">Blocks</a>
                      </li>
                      <li>
                        <a href="#Sectiontransaction" class="text-white font-normal not-italic text-base hover:text-primary">Transactions</a>
                      </li>
                      <li>
                        <a href="#SectionNetwork" class="text-white font-normal not-italic text-base hover:text-primary">Network</a>
                      </li>
                      <li>
                        <a href="#SectionRewards" class="text-white font-normal not-italic text-base hover:text-primary">Rewards</a>
                      </li>
                      <li>
                        <a href="#SectionWhyUs" class="text-white font-normal not-italic text-base hover:text-primary">Why Us</a>
                      </li>
                      <li>
                        <a href="https://jexa.gitbook.io/jexa-foundation" target="_blank" class="text-white font-normal not-italic text-base hover:text-primary">Documentation</a>
                      </li>
                    </ul>
                  </nav>

                  <div class="btn-group items-center gap-3 flex-1 justify-end hidden xl:flex">
                    <a href="https://app.jexa.io/login" class="bg-jexagradient py-3 px-7 rounded-full text-white hover:bg-primary " type="button">Stake Now</a>
                  </div>
              </div>

              <!-- Mob menu -->

              <transition name="fade" >
                <div class="bg-gradient w-full" :class="{ 'hidden': !isMenuOpen }"  >
                  <div class="mob__menu fixed top-0 left-0 bottom-0 z-50 bg-gradient w-80 p-5 pt-16 transition-all duration-700 ease-in-out" >
                      <div class="bottom_header max-w-screen-xl 2xl:max-w-1450 mx-auto flex flex-row items-center lg:border-none top-0 w-full z-50">
                            <div class="container flex items-center  w-full relative">
                                <nav class="flex flex-col gap-6" >
                                      <ul class="flex flex-col gap-8 items-center">
                                          <li>
                                            <a href="#Sectionblock" class="text-white font-normal not-italic text-base hover:text-primary">Blocks</a>
                                          </li>
                                          <li>
                                            <a href="#Sectiontransaction" class="text-white font-normal not-italic text-base hover:text-primary">Transactions</a>
                                          </li>
                                          <li>
                                            <a href="#SectionNetwork" class="text-white font-normal not-italic text-base hover:text-primary">Network</a>
                                          </li>
                                          <li>
                                            <a href="#SectionRewards" class="text-white font-normal not-italic text-base hover:text-primary">Rewards</a>
                                          </li>
                                          <li>
                                            <a href="#SectionWhyUs" class="text-white font-normal not-italic text-base hover:text-primary">Why Us</a>
                                          </li>
                                          <li>
                                            <a href="https://jexa.gitbook.io/jexa-foundation" target="_blank" class="text-white font-normal not-italic text-base hover:text-primary">Documentation</a>
                                          </li>
                                        </ul>
                                    <div class="btn-group items-center gap-3 flex">
                                      <a href="https://app.jexa.io/login" class="bg-jexagradient py-3 px-7 rounded-full text-white hover:bg-primary" type="button">Stake Now</a>
                                  </div>
                                  <span class="absolute top-0 right-0">
                                    <img src="/assets/images/close.svg" alt="" class="w-5" @click="closeSidebar" />
                                  </span>
                                </nav>
                            </div>
                      </div>
                  </div>
                </div>
            </transition>
        </div>
      </header>
    </div>
</template>

<script>
export default {
  name: 'HeaderComponents',
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.toggleBodyOverflow();
    },
    closeSidebar() {
      this.isMenuOpen = false;
      this.toggleBodyOverflow();
    },
    toggleBodyOverflow() {
      if (this.isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  watch: {
    isMenuOpen() {
      this.toggleBodyOverflow();
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
