<template>
  <div class="w-full">
    <div class="relative w-full">
      <div class="blocks-cards grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 z-20 ">
        <div v-if="isMobile" :class="[borderClass]" class="bg-transacbg inner-card transition-all duration-500 ease-in-out cursor-pointer rounded-xl p-3 shadow-xl border border-newBlue">
          <div class="main_header flex justify-between items-center mb-2">
            <div class="rank font-semibold text-primary"># <span>{{ records[0].rank }}</span></div>
            <a :href="records[0].link" class="bg-blockBox text-white flex gap-1 items-center py-1 px-2 text-xs rounded-md">{{ records[0].name }} <img src="/assets/images/chevron.svg" alt="" class="w-3 h-3"></a>
          </div>
          <div class="flex justify-between items-center">
            <div class="time text-xs text-white">{{ records[0].time }}</div>
            <b class="text-xs text-white">{{ records[0].transactions }} <span>Txns</span></b>
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="rewards text-xs text-white">{{ records[0].reward }}</div>
            <b class="text-xs flex items-center gap-1 text-white"><img src="/assets/images/flame.svg" alt="" class="w-3 h-3"> <span>{{ records[0].total }}</span></b>
          </div>
        </div>
        
        <div v-else v-for="(record, index) in visibleRecords" :key="index" :class="[borderClass]" class="bg-transacbg inner-card transition-all duration-500 ease-in-out cursor-pointer rounded-xl p-3 shadow-xl border border-newBlue ">
          <div class="main_header flex justify-between items-center mb-2">
            <div class="rank font-semibold text-primary"># <span>{{ record.rank }}</span></div>
            <a :href="record.link" class="bg-blockBox text-white flex gap-1 items-center py-1 px-2 text-xs rounded-md">{{ record.name }} <img src="/assets/images/chevron.svg" alt="" class="w-3 h-3"></a>
          </div>
          <div class="flex justify-between items-center ">
            <div class="time text-xs text-white">{{ record.time }}</div>
            <b class="text-xs text-white">{{ record.transactions }} <span>Txns</span></b>
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="rewards text-xs text-white">{{ record.reward }}</div>
            <b class="text-xs flex items-center gap-1 text-white"><img src="/assets/images/flame.svg" alt="" class="w-3 h-3"> <span>{{ record.total }}</span></b>
          </div>
        </div>
      </div>

      <div class="block-line shadow-OutShadow h-4 absolute top-8 w-full -z-20 hidden md:block">
        <div class="behind-pipe shadow-pipeShadow bg-NewpipeBg w-full h-full">
          <div :class="[moverColor]" class="h-4 w-16 duration-300 animate-slideMove bg-cover bg-center bg-no-repeat"></div>
        </div>
      </div>
      <div class="block-line shadow-OutShadow h-4 absolute top-16 w-full -z-20 hidden md:block">
        <div class="behind-pipe shadow-pipeShadow bg-NewpipeBg w-full h-full">
          <div :class="[moverColor]" class="h-4 w-16 duration-300 animate-slidereverse bg-cover bg-center bg-no-repeat"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockComponents',
  props: {
    borderClass: { 
      type: String,
      default: '' 
    },
    moverColor: { 
      type: String,
      default: '' 
    },
    isMobile: { 
      type: Boolean,
      default: false
    },
    isTab: { 
      type: Boolean,
      default: false
    },
    records: Array
  },
  
  data(){
    return{
      
    }
  },
  mounted() {
  const self = this;
  function handleResize() {
    self.visibleRecords;
  }
  // window.addEventListener('resize', (e) => {
  //   console.log(e);
  // });
  handleResize();
},
unmounted(){
  // window.removeEventListener("resize");
},
  computed: {
 
  visibleRecords() {
    if (this.isMobile) {
      return [this.records[0]];
    } else if (this.isTab) {
      return this.records.slice(0, 3);
    } else {
      return this.records;
    }
  }
}
  
}
</script>

<style scoped >
.pipeBg{
  background: linear-gradient(0deg,hsla(0,0%,100%,.65),hsla(0,0%,100%,.65)),#c2c9d1;
}

.moving1{
  background-image: url('/public/assets/images/mover1.svg');
}
.moving2{
  background-image: url('/public/assets/images/mover2.svg');
}
.moving3{
  background-image: url('/public/assets/images/mover3.svg');
}
.moving4{
  background-image: url('/public/assets/images/mover4.svg');
}
.moving5{
  background-image: url('/public/assets/images/mover5.svg');
}
.moving6{
  background-image: url('/public/assets/images/mover6.svg');
}
.moving7{
  background-image: url('/public/assets/images/mover7.svg');
}
.moving8{
  background-image: url('/public/assets/images/mover8.svg');
}
.moving9{
  background-image: url('/public/assets/images/mover9.svg');
}
.moving10{
  background-image: url('/public/assets/images/mover10.svg');
}
</style>
