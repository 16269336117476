<template>
  <div>
    <Header/>
        <router-view/>
    <Footer/>
  </div>
</template>

<script >
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default ({
  components:{
    Header,
    Footer    
  }
})
</script>

<style lang="scss">
  @font-face {
    font-family:'Regola Pro';
    src: url(../public/assets/fonts/RegolaProBook.otf),
    url(../public/assets/fonts/RegolaProRegular.otf),
    url(../public/assets/fonts/RegolaProMedium.otf),
    url(../public/assets/fonts/RegolaProBold.otf);
  }
  body{
    font-family:'Regola Pro';
    // background-image:radial-gradient(circle at 50% 0,rgba(237,237,237,.65),rgba(242,235,255,.88) 65%) ;
    background-image:linear-gradient(90deg, rgb(1, 23, 38) 0%, 24.0826%, rgb(0, 68, 102) 48.1651%, 74.0826%, rgb(0, 24, 39) 100%);
  }

</style>
