<template>
  <div>
    <footer class="px-4 pb-4 md:px-10 md:pb-8 mt-14">
      <div class="main-footer flex flex-col gap-y-6 md:gap-y-0 md:flex-row justify-between md:items-center">
        <div class="footer-logo w-full md:w-52 flex justify-center">
          <router-link to="/" class="text-4xl">
            <img src="/assets/images/logo.png" alt="" width="200">
          </router-link>
        </div>
        <div class="footer-menu flex justify-center">
          <ul class="flex mb-0 flex-row justify-center items-center flex-wrap gap-x-3 gap-y-3 md:gap-x-8  md:gap-y-3 max-w-[320px] md:max-w-[800px] w-full">
              <li>
                <router-link to="/" class="text-sm font-medium hover:font-semibold non-italic tracking-wider text-primary">Home</router-link>
                </li>
              <li>
                <a href="#SectionWhyUs" class="text-sm font-medium hover:font-semibold non-italic tracking-wider text-primary">FAQ</a>
                </li>
              <li>
                <a href="/Jexa-Business-Plan.pdf" target="_blank" class="text-sm font-medium hover:font-semibold non-italic tracking-wider text-primary">Referrals</a>
                </li>
              
              <li>
                <a href="https://jexa.gitbook.io/jexa-foundation/privacy-policy/page-1" target="_blank" class="text-sm font-medium hover:font-semibold non-italic tracking-wider text-primary">Privacy</a>
                </li>
            </ul>

        </div>
        <div class="copy-right w-full md:w-52 flex flex-col gap-y-2 items-center text-primary text-sm tracking-wider">
          <div>&copy; {{ currentYear }} Jexa</div>
          <div>
            All rights reserved
          </div>
          <div>
            info@jexa.io
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'FooterComponents',
  data(){
    return{
      currentYear: null
    }
  },
  mounted() {
    this.getCurrentYear();
  },
  methods: {
    getCurrentYear() {
      const date = new Date();
      this.currentYear = date.getFullYear();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
