<template>
  <div class="home">
    <section
      class="pt-0 pb-16 xl:pb-20 main-section relative after:hidden after:md:block after:left-0 after:bg-primary after:w-full after:opacity-1 after:blur-120 after:max-w-1000 after:h-400 after:absolute after:top-29P after:xl:left-25P">
      <div class="container m-auto relative z-30">
        <div
          class="inner-section flex flex-col lg:flex-row items-center gap-5 p-2 md:p-10 bg-Maingradient rounded-32 relative overflow-hidden h-auto xl:h-[750px]">
          <div class="">

            <div
              class="powered-by border rounded-full px-7 py-4 gap-2 hidden md:inline-flex text-white mb-4 relative z-999 text-lg">
              Become A<span class="font-bold text-primary">VALIDATOR</span>
            </div>
            <h1 class="text-4xl md:text-5xl lg:text-7xl font-bold leading-loose text-white mb-6 relative z-999">One
              Investment,<br>
              <span class="text-primary">Countless Rewards.</span>
            </h1>
            <p class="text-white font-medium text-base lg:text-xl mb-5 relative z-999">Invest with JEXA for
              Multi-Blockchain Validator Rewards</p>
            <div class="flex mt-1.5 mb-5 flex-wrap justify-between  md:flex-nowrap relative z-999">
              <div v-for="item in counter" :key="item.key" class="md:min-w-36 md:w-full text-start">
                <h3 class="text-30 font-semibold text-primary non-italic tracking-wider">{{ item.label }}</h3>
                <vue3-autocounter class="text-30 font-semibold text-white non-italic tracking-wider" ref='counter'
                  :startAmount='0' :endAmount='item.value' :duration='4' :autoinit='true' />
                <span class="text-30 font-semibold text-white non-italic tracking-wider"> {{ item.text }}</span>
              </div>
              <div>

              </div>
            </div>
            <div class="flex justify-between flex-wrap gap-y-4 relative z-999">
              <a href="https://app.jexa.io/login"
                class="bg-jexagradient py-4 md:py-3 px-5 md:px-7 rounded-full text-white ">Stake now</a>
              <div class="powered-by border rounded-full px-4 md:px-7 py-4 gap-2  md:hidden text-secondary">
                Empowered by<span class="font-bold text-primary">JEXA</span>
              </div>
            </div>
          </div>
          <div
            class="new__div before:absolute before:top-0 before:bg-videoOverlay before:left-0 before:right-0 before:bottom-0 before:z-99">
            <video autoplay="autoplay" loop="loop" muted="muted" playsInline
              class="absolute w-full h-full top-0 left-0 right-0 bottom-0 object-cover"
              poster="/assets/images/main.png">
              <source src="https://wisecdn.b-cdn.net/video-frame.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </section>

    <section id="Sectionblock" class="py-16 px-2 xl:px-0 xl:py-36 blocks-section bg-cover bg-no-repeat bg-center">
      <div class="container m-auto flex flex-col  gap-10">
        <div class="main-heading new-heading flex justify-center relative flex-col">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize">Live
            Blocks</span>
          <h2
            class="text-3xl md:text-6xl font-bold tracking-wide !leading-tight text-center bg-Newgradient bg-clip-text text-transparent relative">
            Live Blocks
          </h2>
        </div>
        <div class="flex flex-col gap-5 md:gap-2">
          <div class="flex  gap-2 md:gap-6 items-center ">
            <div @click="goToScan('https://bscscan.com/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-binance cursor-pointer">
              <img src="/assets/images/binance.svg" alt="" class="min-w-14 h-14 rounded-2xl">
              <span class="hidden md:inline-block font-semibold text-xl text-white">Binance</span>
            </div>
            <BlocksView :records="bsc_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving4"
              borderClass="" />
          </div>

          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://tronscan.org/#/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-trx cursor-pointer ">
              <img src="/assets/images/tron.png" alt="" class="min-w-14 h-14 rounded-2xl">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Tron</span>
            </div>
            <BlocksView :records="tron_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving1"
              borderClass="" />
          </div>

          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://etherscan.io/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-EthBG cursor-pointer">
              <img src="/assets/images/ethereum-eth-logo.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-EthBG">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Etherum</span>
            </div>
            <BlocksView :records="eth_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving2"
              borderClass="" />
          </div>

          <!-- <div class="flex  gap-2 md:gap-6 items-center">
            <div
              class="p-1  flex flex-col justify-center bg-[#002033] items-center h-100 w-auto md:w-150 rounded-xl relative before:hidden md:before:block md:before:absolute md:before:top-0 md:before:left-0 md:before:right-0 md:before:bottom-0 md:before:-z-10 before:-m-0.5 md:before:rounded-xl md:before:bg-solgradient">
              <img src="/assets/images/solana-sol-logo.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-black">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Solana</span>
            </div>
            <BlocksView :records="records" :isTab="isTab" :isMobile="isMobile" moverColor="moving3" borderClass="" />
          </div> -->

          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://arbiscan.io/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-arb cursor-pointer">
              <img src="/assets/images/arb.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-arb">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Arbitrum </span>
            </div>
            <BlocksView :records="arb_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving5"
              borderClass="" />
          </div>
          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://cardanoscan.io/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-ada cursor-pointer">
              <img src="/assets/images/cardano.png" alt="" class="min-w-14 h-14 rounded-full bg-white p-2.5">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Cardano </span>
            </div>
            <BlocksView :records="ada_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving6"
              borderClass="" />
          </div>
          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://ftmscan.com/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-ftm cursor-pointer">
              <img src="/assets/images/ftm.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-ftm">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Fantom </span>
            </div>
            <BlocksView :records="ftm_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving10"
              borderClass="" />
          </div>
          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://cronoscan.com/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-cronos cursor-pointer">
              <img src="/assets/images/cronos.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-white">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Cronos </span>
            </div>
            <BlocksView :records="crono_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving7"
              borderClass="" />
          </div>
          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://polygonscan.com/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-matic cursor-pointer">
              <img src="/assets/images/matic-2.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-matic">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Polygon </span>
            </div>
            <BlocksView :records="matic_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving8"
              borderClass="" />
          </div>
          <div class="flex  gap-2 md:gap-6 items-center">
            <div @click="goToScan('https://celoscan.io/')"
              class="p-1  flex flex-col justify-center   items-center h-100 w-auto md:w-150 rounded-xl md:border-2 md:border-celo cursor-pointer">
              <img src="/assets/images/celo.svg" alt="" class="min-w-14 h-14 p-2.5 rounded-full bg-celo">
              <span class="font-semibold text-xl text-white hidden md:inline-block ">Celo</span>
            </div>
            <BlocksView :records="celo_records" :isTab="isTab" :isMobile="isMobile" moverColor="moving9"
              borderClass="" />
          </div>



        </div>

      </div>
    </section>

    <section id="Sectiontransaction" class="py-16 px-2 xl:px-0 xl:pt-36 xl-pb-20 transaction">
      <div class="container m-auto flex flex-col  gap-10">
        <div class="main-heading new-heading flex justify-center relative">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize">Transactions</span>
          <h2
            class="text-3xl md:text-6xl font-bold tracking-wide !leading-tight text-center bg-Newgradient bg-clip-text text-transparent relative">
            Seamless Transactions
          </h2>
        </div>
        <div class="transaction-detail flex flex-col gap-6">
          <div class="tabs flex justify-center sm:justify-start items-center gap-4 flex-wrap">
            <button v-for="(tab, index) in tabs" :key="index" type="button"
              class="flex items-center gap-2 bg-transparent border border-primary shadow-md text-white text-sm font-medium py-2 px-7 rounded-full hover:bg-jexagradient">
              <img :src="tab.img" alt="" class="h-5"> <span class="capitalize">{{ tab.title }}</span>
            </button>
            <!-- <button type="button" class="flex items-center gap-2 border bg-white shadow-md text-secondary text-sm font-medium py-2 px-7 rounded-full hover:bg-purple-100">
            <img src="/assets/images/ethereum-eth.svg" alt="" class="w-5 h-5">  ETH
          </button>
          <button type="button" class="flex items-center gap-2 border bg-white shadow-md text-secondary text-sm font-medium py-2 px-7 rounded-full hover:bg-purple-100">
           <img src="/assets/images/solana-sol-logo.svg" alt="" class="w-5 h-5"> SOL
          </button> -->

          </div>
          <div class="transaction">
            <TransactionView moverColor="moving1" :transactions="eth_transactions" />
          </div>
        </div>
      </div>
    </section>

    <section id="SectionNetwork" class="py-0 xl:py-20 stats-section bg-cover bg-no-repeat bg-center">
      <div class="container m-auto flex flex-col rounded-32 py-14 p-6">
        <div class="main-heading new-heading flex justify-center relative">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize md:hidden">Validators
          </span>
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize hidden md:inline-block">Validators
            Network</span>
          <h2
            class="text-3xl md:text-6xl font-semibold tracking-wide !leading-tight text-center bg-Newgradient bg-clip-text text-transparent capitalize relative ">
            Global Validators Network
          </h2>
        </div>
        <div class="flex flex-col gap-5">
          <div class="flex-1">
            <div class="">
              <WorldMap />
            </div>
          </div>
          <div class="country-name">
            <ul class="rounded-xl flex justify-center gap-4 flex-wrap">
              <li v-for="(item, index) in country" :key="index" :class="item.hoverClass"
                class="px-6 py-1.5 text-primary border-primary hover:bg-country1 hover:text-white transition duration-300 rounded-full cursor-pointer flex items-center gap-1.5 border">
                <img :src="item.imgSrc" alt=""> <span class="capitalize">{{ item.text }}</span>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </section>

    <section id="SectionRewards" class="py-16 px-2 xl:px-0 xl:pt-36 xl-pb-20  how-its-work ">
      <div class="container m-auto flex flex-col gap-10">
        <div class="main-heading new-heading how-it flex flex-col items-center gap-4 justify-center relative">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize md:hidden">
            Rewards</span>
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize hidden md:inline-block">Earning
            Rewards</span>
          <h2
            class="text-3xl md:text-6xl font-bold tracking-wide !leading-tight text-center bg-Newgradient z-10 bg-clip-text text-transparent relative">
            Validator Earning Rewards
          </h2>
          <p class="text-primary text-xl font-semibold text-center">Stake with Confidence and Unlock Substantial Rewards
            as a Validator
          </p>
        </div>
        <div class="main-cards flex flex-wrap items-center justify-center gap-5 md:gap-10 ">
          <div v-for="(item, index) in rewards" :key="index"
            class="cards-main bg-workBG p-5 lg:p-10 filter-dropShadow rounded-xl relative flex flex-col gap-6 justify-between w-80 lg:w-96 lg:h-96">
            <div class="card-header flex flex-col justify-between">
              <div class="img  top-0 w-full" :class="item.positon">
                <img :src="item.img" alt="" class="w-full h-48 ">
              </div>
            </div>
            <div class="card-body">
              <div class="nmbr mb-2">
                <h2 class="text-3xl font-light text-primary">{{ item.text }}</h2>
              </div>
              <p class="text-base font-medium text-white">{{ item.subtext }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="SectionWhyUs" class="py-16 px-2 xl:px-0 xl:pt-36 xl-pb-20  faq">
      <div class="container m-auto flex flex-col gap-10">
        <div class="main-heading new-heading how-it flex flex-col items-center gap-4 justify-center relative">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize">Why
            Join us?</span>
          <h2
            class="text-3xl md:text-6xl font-bold tracking-wide !leading-tight text-center bg-Newgradient z-10 bg-clip-text text-transparent relative capitalize">
            Why Join us?
          </h2>
        </div>
        <div class="accordion">
          <ul class="max-w-1000 mx-auto divide-y shadow shadow-primary rounded-xl">
            <li v-for="(item, index) in FAQ" :key="index">
              <details class="group" :open="item.open" @toggle="toggleAccordion(index)">
                <summary class="flex items-center gap-3 px-4 py-5 font-medium marker:content-none hover:cursor-pointer">
                  <svg class="w-5 h-5 text-white transition" :class="{ 'rotate-90 !text-primary': item.open }"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                  </svg>
                  <span class="text-white font-medium" :class="{ '!text-primary': item.open }">{{ item.title }}</span>
                </summary>
                <article class="p-4 bg-workBG">
                  <p class="text-white">{{ item.content }}</p>
                </article>
              </details>
            </li>
          </ul>

        </div>
      </div>
    </section>

    <section class="py-16 px-2 xl:px-0 xl:pt-36 xl-pb-20  social">
      <div class="container m-auto flex flex-col gap-10">
        <div class="main-heading new-heading how-it flex flex-col items-center gap-4 justify-center relative">
          <span
            class="text_shadow text-5xl font-extrabold md:text-108 left-0 mx-auto absolute -top-55 lg:-top-55 xl:-top-84  text-center -z-1 w-full opacity-10 capitalize">Social
            Links
          </span>
          <h2
            class="text-3xl md:text-6xl font-bold tracking-wide !leading-tight text-center bg-Newgradient z-10 bg-clip-text text-transparent relative">
            Social Links
          </h2>
        </div>
        <div class="social-wrap grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10 pt-10 pb-5">
          <div v-for="(platform, index) in socialPlatforms" :key="index" @click="openSocialMedia(platform.link)"
            class="social-inner flex flex-col gap-10 p-5 cursor-pointer rounded-lg transition ease-in-out duration-500 hover:bg-jexagradient">
            <div class="">
              <img :src="platform.image" alt="" class="w-8 h-8 ">
            </div>
            <div>
              <h3 class="font-bold text-xl text-white">{{ platform.name }}</h3>
              <p class="text-white">{{ platform.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-0 px-2 xl:px-0 xl:py-20   cta-section">
      <div class="container m-auto flex flex-col gap-10  py-20 rounded-lg md:rounded-32 relative">
        <div
          class="main-heading cta__heading before:animate-hflip before:hidden after:hidden flex flex-col items-center gap-4 sm:before:block sm:after:block justify-center before:absolute before:w-56 before:h-56 before:-top-0 before:left-0 before:bg-no-repeat before:bg-contain before:bg-center after:absolute after:w-56 after:h-56 after:bottom-0 after:right-0 after:bg-no-repeat after:bg-contain after:bg-center">
          <h2
            class="text-center text-3xl md:text-4xl lg:5xl text-white xl:text-80 font-semibold tracking-wide !leading-none py-5 relative z-999">
            Accelerate Your <br> Earnings Today.
          </h2>
          <a href="https://app.jexa.io/login"
            class="bg-jexagradient relative z-999  text-white py-4 px-12 rounded-full hover:transition hover:duration-500 hover:ease-in hover:bg-NewBlue shadow-2xl hover:scale-110">Become
            a validator</a>
        </div>
        <div
          class="new__div before:absolute before:top-0 before:bg-videoOverlay before:left-0 before:right-0 before:bottom-0 before:z-99">
          <video autoplay="autoplay" loop="loop" muted="muted" playsInline
            class="absolute w-full h-full top-0 left-0 right-0 bottom-0 object-cover rounded-lg md:rounded-32">
            <source src="https://wisecdn.b-cdn.net/cubes-3d-background.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import BlocksView from '@/components/BlocksView.vue';
import WorldMap from '@/components/WorldMap.vue';
import TransactionView from '@/components/TransactionView.vue';
import Vue3Autocounter from 'vue3-autocounter';

// import Web3 from 'web3';

export default {
  name: 'HomeView',
  components: {
    BlocksView,
    WorldMap,
    TransactionView,
    'vue3-autocounter': Vue3Autocounter
  },
  data() {
    return {

      isMobile: false,
      isTab: false,
      socialPlatforms: [
        {
          name: 'X',
          image: '/assets/images/twitter.svg',
          description: 'Follow us on X.',
          link: "https://twitter.com/jexa203"
        },
        {
          name: 'Facebook',
          image: '/assets/images/facebook.svg',
          description: 'Follow us on Facebook.',
          link: "https://www.facebook.com/people/Jexa/61559184389455/"
        },
        {
          name: 'Telegram',
          image: '/assets/images/telegram.svg',
          description: 'Follow us on Telegram.',
          link: "#"
        },
        {
          name: 'Instagram',
          image: '/assets/images/instagram.svg',
          description: 'Follow us on Discord.',
          link: "https://www.instagram.com/jexaofficials/"
        }
      ],
      FAQ: [
        {
          "title": "What is a validator and how does it relate to JEXA?",
          "content": "A validator is a participant in a blockchain network responsible for validating transactions. JEXA offers opportunities for investors to become validators, earning rewards by participating in blockchain networks.",
          "open": false
        },
        {
          "title": "How do validators earn rewards with JEXA?",
          "content": "Validators earn rewards by staking their cryptocurrency assets with JEXA to participate in the validation process of various blockchain networks. These rewards are earned through validating transactions and contributing to network security.",
          "open": false
        },
        {
          "title": "What types of rewards can I expect as a validator with JEXA?",
          "content": "Validators can earn various rewards, including block rewards, transaction fees, staking rewards, and other incentives offered by blockchain networks, all facilitated through JEXA's validator services.",
          "open": false
        },
        {
          "title": "What are the risks associated with validator investment through JEXA?",
          "content": "While validator investments offer potential rewards, there are no risks. JEXA helps mitigate these risks through careful selection of networks and validator management.",
          "open": false
        },
        {
          "title": "What are the requirements to become a validator with JEXA?",
          "content": "Requirements may include technical proficiency, staking a minimum amount of cryptocurrency, and adhering to specific network guidelines. JEXA provides guidance and support to investors to meet these requirements.",
          "open": false
        },
        {
          "title": "How do I get started as a validator with JEXA?",
          "content": "To begin investing as a validator with JEXA, interested individuals can explore available opportunities, review eligibility criteria, and follow the provided instructions for onboarding and staking their assets.",
          "open": false
        },
        {
          "title": "How can I trust JEXA with my validator investments?",
          "content": "JEXA is committed to transparency, security, and providing value to its investors. With a track record of reliable services and expertise in blockchain technology, investors can trust JEXA to manage their validator investments effectively and maximize potential rewards.",
          "open": false
        }
      ],
      tabs: [
        {
          title: "BSC",
          img: "/assets/images/binance.svg",
        },
        {
          title: "TRX",
          img: "/assets/images/tron.png",
        },
        {
          title: "ETH",
          img: "/assets/images/ethereum-eth.svg",
        },
        // {
        //   title: "SOL",
        //   img: "/assets/images/solana-sol-logo.svg",
        // },
        {
          title: "ARB",
          img: "/assets/images/arb.svg",
        },
        {
          title: "ada",
          img: "/assets/images/cardano-white.png",
        },
        {
          title: "Cronos",
          img: "/assets/images/cronos.svg",
        },
        {
          title: "Matic",
          img: "/assets/images/matic.svg",
        },
        {
          title: "FTM",
          img: "/assets/images/ftm.svg",
        },
        {
          title: "CELO",
          img: "/assets/images/celo.svg",
        },

      ],
      country: [
        {
          imgSrc: "/assets/images/india.svg",
          text: "India",
          hoverClass: 'btn_country1 hover:bg-country1 hover:border-country1',
        },
        {
          imgSrc: "/assets/images/usa.svg",
          text: "USA",
          hoverClass: 'btn_country2 hover:bg-country2 hover:border-country2',
        },
        {
          imgSrc: "/assets/images/brazil.svg",
          text: "Brazil",
          hoverClass: 'btn_country3 hover:bg-country3 hover:border-country3',
        },
        {
          imgSrc: "/assets/images/germany.svg",
          text: "germany",
          hoverClass: 'btn_country4 hover:bg-country4 hover:border-country4',
        },
        {
          imgSrc: "/assets/images/australia.svg",
          text: "Australia",
          hoverClass: 'btn_country5 hover:bg-country5 hover:border-country5',
        },
        {
          imgSrc: "/assets/images/dubai.svg",
          text: "dubai",
          hoverClass: 'btn_country6 hover:bg-country6 hover:border-country6',
        },
        {
          imgSrc: "/assets/images/russia.svg",
          text: "russia",
          hoverClass: 'btn_country7 hover:bg-country7 hover:border-country7',
        },
      ],
      counter: [
        {
          key: 'tvl',
          label: 'TVL',
          value: '',
          text: 'M',
        },
        {
          key: 'apy',
          label: 'APY',
          value: '',
          text: '%'
        },
        {
          key: 'holders',
          label: 'Holders',
          value: '',
        }
      ],
      cards: [
        {
          number: "01.",
          title: "Unlock liquidity on your staked SOL to use across DeFi",
          image: "/assets/images/unlock_liquidity.webp"
        },
        {
          number: "02.",
          title: "Always keep custody of your funds",
          image: "/assets/images/custody_funds.png"
        },
        {
          number: "03.",
          title: "Stake to the first MEV-powered stake pool",
          image: "/assets/images/MEV_rewards.webp"
        }
      ],
      rewards: [
        {
          img: "/assets/images/1.svg",
          nmbr: "01.",
          text: "Block Rewards",
          subtext: "Validators receive cryptocurrency tokens for adding new blocks to the blockchain.",
        },
        {
          img: "/assets/images/2.svg",
          text: "Transaction Fees",
          subtext: "Validators may earn fees from processing transactions on the blockchain.",
        },
        {
          img: "/assets/images/3.svg",
          text: "Validator Incentives",
          subtext: "Some networks offer additional rewards for validators' ongoing participation.",
        },
        {
          img: "/assets/images/4.svg",
          text: "Staking Rewards",
          subtext: "Validators earn rewards for staking their own tokens to support the network.",
        },
        {
          img: "/assets/images/5.svg",
          text: "Participation Rewards",
          subtext: "Validators receive rewards for actively engaging in network governance.",
        },
      ],

      // Blocks 
      records: [
        {
          rank: '60,896,628',
          link: '/',
          name: 'TronSpark',
          time: '11 secs ago',
          transactions: '200',
          reward: 'Reward 176 TRX',
          total: '313.792 TRX'
        },
        {
          rank: '60,896,628',
          link: '/',
          name: 'TronSpark',
          time: '11 secs ago',
          transactions: '200',
          reward: 'Reward 176 TRX',
          total: '313.792 TRX'
        },
        {
          rank: '60,896,628',
          link: '/',
          name: 'TronSpark',
          time: '11 secs ago',
          transactions: '200',
          reward: 'Reward 176 TRX',
          total: '313.792 TRX'
        },
        {
          rank: '60,896,628',
          link: '/',
          name: 'TronSpark',
          time: '11 secs ago',
          transactions: '200',
          reward: 'Reward 176 TRX',
          total: '313.792 TRX'
        },
      ],

      bsc_records: [],
      tron_records: [],
      eth_records: [],
      arb_records: [],
      ada_records: [],
      ftm_records: [],
      crono_records: [],
      matic_records: [],
      celo_records: [],

      hasInterval: null,

      eth_transactions: [],
    }
  },

  created(){
    this.fetchCounterData()
  },
  mounted() {


    // validators map focus and color

    function handleHover(btnClass, stClass, fillColors, strokeColors, strokeWidths) {
      const btns = document.querySelectorAll(btnClass);
      const sts = document.querySelectorAll(stClass);

      btns.forEach((btn, index) => {
        btn.addEventListener('mouseover', () => {
          sts[index].style.fill = fillColors[index];
          sts[index].style.stroke = strokeColors[index];
          sts[index].style.strokeWidth = strokeWidths[index] + 'px';
        });

        btn.addEventListener('mouseout', () => {
          sts[index].style.fill = '';
          sts[index].style.stroke = ''; // Reset stroke color
          sts[index].style.strokeWidth = ''; // Reset stroke width
        });
      });
    }

    handleHover('.btn_country1', '.st155', ['#f68c2b'], ['white'], [1]);
    handleHover('.btn_country2', '.st234, .st256', ['#5b8828', '#5b8828'], ['white', 'white'], [1, 1]);
    handleHover('.btn_country3', '.st200', ['#0066b3'], ['white'], [1]);
    handleHover('.btn_country4', '.st46', ['#91278f'], ['white'], [1]);
    handleHover('.btn_country5', '.st16', ['#da2572'], ['white'], [1]);
    handleHover('.btn_country6', '.st135', ['#d4cc36'], ['white'], [1]);
    handleHover('.btn_country7', '.st176', ['#d41b23'], ['white'], [1]);


    // block show 
    const SetWidth = (innerWidth) => {
      this.isMobile = innerWidth <= 767 ? true : false
      this.isTab = innerWidth >= 768 && innerWidth <= 1280 ? true : false
    }

    window.addEventListener('resize', (e) => {
      let width = e.target.innerWidth;

      SetWidth(width)
    });

    SetWidth(window.innerWidth);

    this.getDashBlocks()

    this.hasInterval = setInterval(() => {
      this.getDashBlocks()
    }, 5 * 1000);

  },
  unmounted() {
    clearInterval(this.hasInterval);
  },
  methods: {

    async fetchCounterData(){
      let data = await fetch("/counter.json").then(r => r.json());
      this.counter = data;
    },

    goToScan(scan_link){
      window.open(scan_link,'_blank');
    },

    openSocialMedia(link){
      window.open(link, "_blank");
    },

    toggleAccordion(index) {
      this.FAQ[index].open = !this.FAQ[index].open;
    },

    formatRecord({ block_num, validator, age, transactions, Reward, BurntFees }) {
      return {
        rank: block_num,
        link: '/',
        name: validator,
        time: age,
        transactions: transactions,
        reward: `Reward ${Reward}`,
        total: BurntFees
      };
    },
    calculateAge(timestamp) {
      console.log(timestamp);
    },

    async getDashBlocks() {

      let data = await fetch("https://node.jexa.io/dash/blocks").then(r => r.json());

      let blocks_data = data?.data;

      let covertRecord = this.formatRecord;

      const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";


      // BSC RECORDS
      this.bsc_records = blocks_data.bsc?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el.Reward,
          BurntFees: el.BurntFees
        })
      }) || new Array(4).fill({});

      // TRON RECORDS
      this.tron_records = blocks_data.tron?.map(el => {
        return covertRecord({
          block_num: el.number,
          validator: el.witnessName?.substring(0, 10) + " ...",
          age: el.timestamp,
          transactions: el.nrOfTrx,
          Reward: parseFloat(parseFloat(el.blockReward) + parseFloat(el.voteReward)).toString() + " TRX",
          BurntFees: `${parseFloat(el.fee)} TRX`
        })
      }) || new Array(4).fill({});

      // ETH RECORDS
      this.eth_records = blocks_data.eth?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el.Reward,
          BurntFees: el.BurntFees
        })
      }) || new Array(4).fill({});

      // ARB RECORDS
      this.arb_records = blocks_data.arb?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: NULL_ADDRESS?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: el?.BurntFees || "0"
        })
      }) || new Array(4).fill({});

      // ADA RECORDS
      this.ada_records = blocks_data.ada?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Timestamp,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: `${el?.GasUsed} ADA` || "0"
        })
      }) || new Array(4).fill({});

      // FTM RECORDS
      this.ftm_records = blocks_data.ftm?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: NULL_ADDRESS?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: el?.BurntFees || "0"
        })
      }) || new Array(4).fill({});

      // Crono RECORDS
      this.crono_records = blocks_data.crono?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: el?.BurntFees || "0"
        })
      }) || new Array(4).fill({});

      // Matic RECORDS
      this.matic_records = blocks_data.matic?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: el?.BurntFees || "0"
        })
      }) || new Array(4).fill({});

      // Celo RECORDS
      this.celo_records = blocks_data.celo?.map(el => {
        return covertRecord({
          block_num: el.Block,
          validator: el.Validator?.substring(0, 10) + " ...",
          age: el.Age,
          transactions: el.Txn,
          Reward: el?.Reward || "-",
          BurntFees: el?.BurntFees || "0"
        })
      }) || new Array(4).fill({});

      let TransactionClasses = [
        "from-indigo-500",
        "from-cyan-500 to-blue-500",
        "from-indigo-500 via-purple-500 to-pink-500",
        "from-indigo-500 to-emerald-500",
        "from-gray-900 to-neutral-100",
        "from-red-100 to-cyan-400",
        "from-yellow-500 to-yellow-100",
        "from-lime-500 to-lime-100",
        "from-green-500 to-green-200",
        "from-purple-500 to-purple-100",
      ];

      // ETH TRANSACTIONS
      this.eth_transactions = blocks_data.eth_transactions?.map((el,index) => {
        return {
          "type": el.Method,
          "address": el.Hash,
          "timestamp": el.Age,
          "vault": el.To,
          "contract": el.From,
          "amount": el.Value,
          "fee": `${el?.GasFees || 0} ETH`,
          "cls": TransactionClasses[index],
        }
      }) || new Array(10).fill({});

    }

  }
}
</script>

<style scoped>
.cta__heading::before,
.cta__heading::after {
  background-image: url('/public/assets/images/jexa.png');
}

.text_shadow {
  -webkit-text-fill-color: #FFF;
}

:is(.blocks-section, .stats-section) {
  background-image: url('/public/assets/images/bg.svg');
}

.filter-dropShadow {
  filter: drop-shadow(0 0 .9375rem rgba(109, 83, 255, .2));
}
</style>